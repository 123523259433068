import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  "data-bs-backdrop": "static",
  "data-bs-keyboard": "false",
  id: "general_update_modal",
  tabindex: "-1",
  ref: "createAPIKeyModalRef",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-lg" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-header bg-primary" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "row justify-content-around" }
const _hoisted_7 = { class: "row justify-content-around" }
const _hoisted_8 = { class: "col-md-5" }
const _hoisted_9 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "col-md-5" }
const _hoisted_13 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_14 = { class: "fv-plugins-message-container" }
const _hoisted_15 = { class: "fv-help-block" }
const _hoisted_16 = { class: "col-md-5" }
const _hoisted_17 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_18 = { class: "fv-plugins-message-container" }
const _hoisted_19 = { class: "fv-help-block" }
const _hoisted_20 = { class: "col-md-5" }
const _hoisted_21 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_22 = { class: "fv-plugins-message-container" }
const _hoisted_23 = { class: "fv-help-block" }
const _hoisted_24 = { class: "col-md-5" }
const _hoisted_25 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_26 = ["value"]
const _hoisted_27 = { class: "fv-plugins-message-container" }
const _hoisted_28 = { class: "fv-help-block" }
const _hoisted_29 = { class: "col-md-5" }
const _hoisted_30 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_31 = { class: "fv-plugins-message-container" }
const _hoisted_32 = { class: "fv-help-block" }
const _hoisted_33 = { class: "col-md-5" }
const _hoisted_34 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_35 = { class: "fv-plugins-message-container" }
const _hoisted_36 = { class: "fv-help-block" }
const _hoisted_37 = { class: "col-md-5" }
const _hoisted_38 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_39 = { class: "fv-plugins-message-container" }
const _hoisted_40 = { class: "fv-help-block" }
const _hoisted_41 = { class: "modal-footer flex-center" }
const _hoisted_42 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_create_api_key_submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Modal "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[10] || (_cache[10] = _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Update Tracker Data ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalClose())),
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "editnewmodalclose"
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              }, null, -1 /* HOISTED */)
            ]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createCommentVNode(" {{ JSON.stringify(tracker) }} "),
              _createVNode(_component_Form, {
                id: "kt_modal_tracker_update",
                class: "form",
                onSubmit: _ctx.submit,
                "validation-schema": _ctx.validationSchema
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createCommentVNode("begin::Label"),
                        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Name", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "name",
                          placeholder: "Tracker Name",
                          modelValue: _ctx.tracker.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tracker.name) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_ErrorMessage, { name: "name" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createCommentVNode("begin::Label"),
                        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Variant ID", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "variant_id",
                          placeholder: "Variant ID",
                          modelValue: _ctx.tracker.variant_id,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tracker.variant_id) = $event)),
                          disabled: ""
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_ErrorMessage, { name: "variant_id" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createCommentVNode("begin::Label"),
                        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Default Radius", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "number",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "default_radius",
                          placeholder: "Default Radius",
                          modelValue: _ctx.tracker.default_radius,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tracker.default_radius) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_ErrorMessage, { name: "default_radius" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createCommentVNode("begin::Label"),
                        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Battery", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "number",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "battery",
                          placeholder: "Battery",
                          modelValue: _ctx.tracker.battery,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tracker.battery) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_ErrorMessage, { name: "battery" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createCommentVNode("begin::Label"),
                        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Customer", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Select"),
                        _createVNode(_component_Field, {
                          name: "se_account_id",
                          "data-placeholder": "Select Customer...",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.tracker.se_account_id,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tracker.se_account_id) = $event))
                        }, {
                          default: _withCtx(() => [
                            _cache[15] || (_cache[15] = _createElementVNode("option", { value: "" }, "Select Customer...", -1 /* HOISTED */)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: i,
                                value: customer.id
                              }, _toDisplayString(customer.name), 9 /* TEXT, PROPS */, _hoisted_26))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createVNode(_component_ErrorMessage, { name: "se_account_id" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _createCommentVNode("begin::Label"),
                        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Fence Status", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "fence_status",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "enable/disable",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.tracker.fence_status,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tracker.fence_status) = $event))
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createElementVNode("option", { value: "" }, "enable/disable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_component_ErrorMessage, { name: "fence_status" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createCommentVNode("begin::Label"),
                        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "GPS Validity", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "gps_validity",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "enable/disable",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.tracker.gps_validity,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tracker.gps_validity) = $event))
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createElementVNode("option", { value: "" }, "enable/disable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("div", _hoisted_36, [
                            _createVNode(_component_ErrorMessage, { name: "gps_validity" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("div", _hoisted_38, [
                        _createCommentVNode("begin::Label"),
                        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Motion Status", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "motion_status",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "enable/disable",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.tracker.motion_status,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.tracker.motion_status) = $event))
                        }, {
                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                            _createElementVNode("option", { value: "" }, "enable/disable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _createVNode(_component_ErrorMessage, { name: "motion_status" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    _createCommentVNode("begin::Button"),
                    _cache[24] || (_cache[24] = _createElementVNode("button", {
                      type: "reset",
                      id: "kt_modal_create_api_key_cancel",
                      class: "btn btn-white me-3"
                    }, " Clear ", -1 /* HOISTED */)),
                    _createCommentVNode("end::Button"),
                    _createCommentVNode("begin::Button"),
                    _createElementVNode("button", _hoisted_42, _cache[23] || (_cache[23] = [
                      _createElementVNode("span", { class: "indicator-label" }, " Update ", -1 /* HOISTED */),
                      _createElementVNode("span", { class: "indicator-progress" }, [
                        _createTextVNode(" Please wait... "),
                        _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                      ], -1 /* HOISTED */)
                    ]), 512 /* NEED_PATCH */),
                    _createCommentVNode("end::Button")
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onSubmit", "validation-schema"])
            ])
          ])
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}