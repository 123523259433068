import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vModelSelect as _vModelSelect, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "card border-0",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  class: "d-flex align-items-center position-relative my-1",
  style: {"width":"1400px"}
}
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-2 mt-2" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_9 = ["value", "onChange", "checked"]
const _hoisted_10 = { class: "d-flex justify-content-start" }
const _hoisted_11 = {
  key: 0,
  href: "#",
  class: "text-gray-600 text-hover-primary mx-1",
  title: "Edit",
  "data-bs-toggle": "modal",
  "data-bs-target": "#general_update_modal"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  class: "modal fade",
  id: "exampleModal",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_15 = { class: "modal-dialog" }
const _hoisted_16 = { class: "modal-content" }
const _hoisted_17 = { class: "modal-body p-5" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "modal-footer" }
const _hoisted_20 = {
  class: "modal",
  "data-bs-backdrop": "static",
  "data-bs-keyboard": "false",
  id: "addroutingmodal",
  ref: "addroutingmodalRef",
  tabindex: "-1"
}
const _hoisted_21 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_22 = { class: "modal-content roundeda" }
const _hoisted_23 = {
  class: "modal-header bg-primary",
  id: "kt_modal_create_api_key_header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_TrackerModal = _resolveComponent("TrackerModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "text-primary mx-10" }, "Trackers", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                "data-kt-subscription-table-filter": "search",
                class: "form-control border mr-3 border-primary form-control-solid w-250px ps-14",
                placeholder: "Search Tracker"
              }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                [_vModelText, _ctx.search]
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("select", {
                style: {"width":"300px"},
                class: "form-select border ms-5 border-primary form-select-solid",
                "data-control": "select2",
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
                "data-dropdown-parent": "#kt_modal_1",
                "data-placeholder": "Select an option",
                "data-allow-clear": "true"
              }, [
                _cache[7] || (_cache[7] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: customer.id
                  }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_6))
                }), 128 /* KEYED_FRAGMENT */))
              ], 32 /* NEED_HYDRATION */)
            ])
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"card-toolbar\" data-v-5298be36><!--begin::Toolbar--><!--begin::Group actions--><div class=\"d-flex justify-content-end align-items-center d-none\" data-kt-subscription-table-toolbar=\"selected\" data-v-5298be36><div class=\"fw-bolder me-5\" data-v-5298be36><span class=\"me-2\" data-kt-subscription-table-select=\"selected_count\" data-v-5298be36></span> Selected </div><button type=\"button\" class=\"btn btn-danger\" data-kt-subscription-table-select=\"delete_selected\" data-v-5298be36> Delete Selected </button></div><!--end::Group actions--></div>", 1)),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.trackerdata,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange,
          onSelectAll: _ctx.selectAll
        }, {
          "cell-checkbox": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("input", {
                class: "form-check-input border border-primary",
                type: "checkbox",
                value: bin.tracker_id,
                onChange: ($event: any) => (_ctx.selectBins(bin)),
                checked: _ctx.isChecked(bin.tracker_id)
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_9),
              _createCommentVNode(" //:checked=\"isChecked(bin.tracker_id)\" ")
            ])
          ]),
          "cell-variant_id": _withCtx(({ row: bin }) => [
            _createElementVNode("div", null, _toDisplayString(bin.variant_id), 1 /* TEXT */)
          ]),
          "cell-se_account_id": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.se_account_id
              ? `${_ctx.customers.find((c) => c.id == bin.se_account_id).name}`
              : "N/A"), 1 /* TEXT */)
          ]),
          "cell-default_radius": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.default_radius), 1 /* TEXT */)
          ]),
          "cell-battery": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.battery), 1 /* TEXT */)
          ]),
          "cell-name": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.name), 1 /* TEXT */)
          ]),
          "cell-fence_status": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.fence_status), 1 /* TEXT */)
          ]),
          "cell-gps_validity": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.gps_validity), 1 /* TEXT */)
          ]),
          "cell-motion_status": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.motion_status), 1 /* TEXT */)
          ]),
          "cell-last_seen_ts": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.last_seen_ts), 1 /* TEXT */)
          ]),
          "cell-diagnostic_uplink": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_10, [
              (_ctx.permissions.includes('tracker-edit'))
                ? (_openBlock(), _createElementBlock("a", _hoisted_11, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`),
                      onClick: ($event: any) => (_ctx.updateData(bin))
                    }, _cache[9] || (_cache[9] = [
                      _createElementVNode("i", { class: "fas fa-edit text-success" }, null, -1 /* HOISTED */)
                    ]), 8 /* PROPS */, _hoisted_12)
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.permissions.includes('tracker-delete'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: "#",
                    class: "text-gray-600 text-hover-primary ms-2",
                    title: "Delete",
                    onClick: ($event: any) => (_ctx.deleteDevice(bin.variant_id))
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`)
                    }, [
                      _createElementVNode("i", { class: "fas fa-trash text-danger" })
                    ], -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_13))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange", "onSelectAll"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("span", {
              class: "modal-title text-white text-uppercase h5 text-primary",
              id: "exampleModalLabel"
            }, " Update Bin Offset "),
            _createElementVNode("div", {
              class: "btn btn-sm",
              "data-bs-dismiss": "modal",
              id: "offsetclosemodal"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[12] || (_cache[12] = _createElementVNode("label", {
                for: "exampleFormControlInput1 ",
                class: "form-label"
              }, "Offset Raw distance", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                class: "form-control my-3",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.offset_raw_distance) = $event)),
                id: "exampleFormControlInput1"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelText, _ctx.payload.offset_raw_distance]
              ])
            ]),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.apply_offset_raw_distance) = $event)),
              class: "form-select my-3",
              "aria-label": "Default select example"
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("option", { selected: "" }, "Open this select menu", -1 /* HOISTED */),
              _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
              _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
            ]), 512 /* NEED_PATCH */), [
              [_vModelSelect, _ctx.payload.apply_offset_raw_distance]
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[14] || (_cache[14] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-bs-dismiss": "modal"
            }, " Close ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.offs(_ctx.payload)))
            }, " Save changes ")
          ])
        ])
      ])
    ]),
    _createCommentVNode(" Bins Routing Configuration Modal "),
    _createElementVNode("div", _hoisted_20, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_21, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_22, [
          _createCommentVNode("begin::Modal header"),
          _createElementVNode("div", _hoisted_23, [
            _createCommentVNode("begin::Modal title"),
            _cache[17] || (_cache[17] = _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Routing Configuration ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalClose())),
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "binsmodal"
            }, _cache[16] || (_cache[16] = [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              }, null, -1 /* HOISTED */)
            ])),
            _createCommentVNode("end::Close")
          ]),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Form")
        ])
      ])
    ], 512 /* NEED_PATCH */),
    _createVNode(_component_TrackerModal, { trackerRecord: _ctx.trackerDataforUpdate }, null, 8 /* PROPS */, ["trackerRecord"]),
    _createCommentVNode(" End Bins Routing Configuration Modal "),
    _createCommentVNode("end::Card")
  ], 64 /* STABLE_FRAGMENT */))
}